/* Pro-table-for-mobile */
.pro-table-for-mobile {
  .hide {
    display: none !important;
  }
  .pro-table-for-mobile__sorter-panel {
    .ant-collapse-content {
      .ant-collapse-content-box {
        tr {
          display: flex;
          flex-direction: column;
        }
        .ant-table-thead {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.pro-table-for-mobile__expandable-modal {
  .ant-modal-content {
    border-radius: 20px;
  }
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: fixed;
}

@primary-color: #454f58;