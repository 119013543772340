.add-person-form {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  .upload-dni {
    .ant-upload-list-picture-card-container {
      height: 100%;
      width: auto;
      margin-top: 30px;
    }
  }

  &__skeleton {
    width: 100%;
    height: 100%;
    &.ant-skeleton-image {
      width: 100%;
      height: 200px;
      border-radius: 10px;
    }
  }
}

@primary-color: #454f58;