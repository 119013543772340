@import '~antd/dist/antd.less';

html,
body,
#root {
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-pro-table-search.ant-pro-table-search-query-filter {
  .renderFormItem {
    height: 40px;
  }
}

.ant-pro-table-search-query-filter .ant-space-item .ant-btn {
  min-width: 80px;
}

.ant-layout {
  min-height: 100vh;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@media (max-width: @screen-xs) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

// 兼容IE11
@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}

/* Css agregado */
.content-search-table {
  width: 100%;
  padding-right: 100px;
}

.ant-table-tbody .custom-table-colum-date {
  text-align: center !important;
}

.hideInSearch .ant-pro-table-search {
  display: none !important;
}

.renderFormItem {
  height: 30px;
}
.renderFormItem.TextArea {
  height: auto !important;
  .ant-row.ant-form-item {
    margin-bottom: 0 !important;
  }
}

.renderFormItemInfo {
  margin-bottom: -20px !important;
  padding: 5px;
  font-style: italic;
}

.TextInfo {
  font-style: italic;
}

.ant-message {
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding-top: 200px !important;
  background: #00000080 !important;
  pointer-events: all;
}

.ant-pro-sider-logo h1 {
  font-family: 'Bebas Neue', Avenir, 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
  letter-spacing: 3.5pt;
}

.site-page-header-responsive {
  height: 300px;
  margin: -24px !important;
  padding: 24px !important;
  background-color: #ffda6aff !important;
}

.ant-modal-content {
  .ant-pro-table {
    padding-bottom: 0px;
  }
}
.ant-pro-table {
  padding-bottom: 70px;
  .ant-pro-table-search {
    margin-bottom: 10px;
    padding: 18px 24px;
    padding-bottom: 0;
    background: #fff;
  }
  .ant-table-content {
    .ant-table-tbody {
      tr {
        td.ant-table-cell:last-child {
          .anticon {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.ant-pro-table-search .ant-form {
  min-height: 40px;
}

.ant-input-number,
.ant-picker {
  width: 100% !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ffda6aff !important;
}

.ant-layout.ant-layout-has-sider > .ant-layout.bigRenderMode {
  overflow: inherit;
}

.ant-pro-global-footer {
  margin: 0 !important;
}

.ResumeText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-modal-confirm-body {
  display: flex;
  .anticon {
    margin-top: 11px;
  }
}

.ant-modal.ImportHelperModal {
  top: 10vh;
  min-width: fit-content;
  .ant-modal-content {
    max-height: 80vh;
  }
  .ant-modal-body {
    height: auto;
  }
  .ImportHelperSubtitle {
    margin-bottom: 24px;
    font-size: 14px;
    font-style: italic;
  }
  .ImportHelperModalImage {
    width: 100%;
    height: 50vh;
    object-fit: contain;
    text-align: center;
  }
}

.Link,
.Link .ant-btn-link {
  padding: 0;
  border: none;
}

.ant-modal-confirm-content {
  font-size: 14px;
}

.ant-pro-table.FullRowSearchOption {
  .ant-col.ant-pro-table-search-option
    > .ant-row.ant-form-item
    > .ant-col.ant-form-item-control {
    max-width: 100%;
  }
}

.Link.Expanded {
  .anticon {
    font-size: 16px;
    svg {
      border-radius: 5px;
    }
  }
}

.RenderFormItemContentPDF {
  .ContentPDF {
    top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    border: 1px solid #d9d9d9;
  }
  .ant-form-item-label {
    display: none;
  }
  .ant-form-item-control {
    max-width: 100%;
  }
}

/* Css para las sub pantallas que ocupan toda la sección */
.FullSectionModal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f2f5;
}

.pointer {
  cursor: pointer;
}

.ant-input-number:-webkit-autofill,
.ant-input:-webkit-autofill {
  background-clip: text;
  -webkit-background-clip: text;
}

input:-webkit-autofill,
input:-internal-autofill-selected,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: black !important;
}

.ant-row.ant-form-item-row {
  > .ant-col.ant-form-item-label {
    > label {
      font-size: 12px;
      font-weight: bold;
    }
  }
}

/* Responsive ProTable */

.ant-pro-table-list-toolbar {
  overflow-x: auto;
  overflow-y: hidden;
  line-height: 1;
}

.ant-pro-table .ant-table-tbody > tr > td .responsive-column-title {
  display: none;
}
@media screen and (max-width: 40rem) {
  .ant-pro-table .ant-pro-table-search .ant-space {
    flex-wrap: wrap;
  }

  .ant-pro-table.responsive-ant-pro-table .ant-table-wrapper {
    margin-bottom: 80px !important;
  }
  .ant-pro-table.responsive-ant-pro-table .ant-table .ant-table-wrapper {
    margin-bottom: 0px !important;
  }

  .ant-pro-table.responsive-ant-pro-table .ant-empty {
    margin: auto;
  }
  .ant-pro-table.responsive-ant-pro-table
    .pro-form-query-filter-actions
    .ant-space-horizontal
    .ant-space-item:last-child {
    min-width: 77px !important;
    text-align: left;
  }
  .ant-pro-table.responsive-ant-pro-table .anticon {
    color: var(--ion-color-dark-contrast);
  }
  .ant-pro-table.responsive-ant-pro-table .ant-table table {
    overflow: hidden;
  }
  .ant-pro-table.responsive-ant-pro-table
    .ant-table-tbody
    > tr
    > td
    .responsive-column-title {
    min-width: 30%;
    display: inline-block;
    font-weight: 700;
    display: block;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 10px;
  }
  .ant-pro-table.responsive-ant-pro-table .ant-card-body {
    padding: 18px;
  }

  .ant-pro-table.responsive-ant-pro-table .ant-table-thead > tr > td,
  .ant-pro-table.responsive-ant-pro-table .ant-table-thead > tr > th {
    display: flex;
    align-items: center;
  }

  .ant-pro-table.responsive-ant-pro-table
    .ant-table-thead
    > tr
    > th:not(.ant-table-column-has-sorters) {
    display: none !important;
  }

  .ant-pro-table.responsive-ant-pro-table .ant-table-tbody > tr > td {
    text-align: left;
    max-width: 100%;
    display: flex;
    align-items: center;
    border: 0;
    width: 75vw !important;
  }

  .ant-pro-table.responsive-ant-pro-table
    .ant-table-tbody
    > tr
    > td:last-child {
    border-bottom: 2px solid var(--ion-color-light-contrast) !important;
  }

  .ant-pro-table .ant-pro-table-list-toolbar-right {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ant-pro-table .ant-pro-table-list-toolbar-right .ant-space-item {
    margin-right: 0 !important;
  }

  .ant-pro-table
    .ant-pro-table-list-toolbar-right
    .ant-space-item:first-child
    .ant-space {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    justify-content: flex-end;
  }
  .ant-pro-table
    .ant-pro-table-list-toolbar-right
    .ant-space-item
    .ant-space-item {
    margin-right: 0 !important;
    margin-bottom: 15px;
  }

  .ant-pro-table
    .ant-pro-table-list-toolbar-right
    .ant-space-item:first-child
    .ant-space-item:first-child {
    width: 100%;
  }
  .content-search-table {
    padding-right: 0;
  }

  .ant-pro-table .ant-pro-table-list-toolbar-right .ant-space-item:last-child {
    width: 100%;
    text-align: right;
  }

  .ant-pro-table
    .ant-pro-table-list-toolbar-right
    .ant-space-item
    .ant-space-item:last-child {
    width: auto;
  }

  .ant-pro-table.responsive-ant-pro-table .ant-table-thead > tr > th,
  .ant-pro-table.responsive-ant-pro-table .ant-table-tbody > tr > th,
  .ant-pro-table.responsive-ant-pro-table .ant-table-thead > tr > td,
  .ant-pro-table.responsive-ant-pro-table .ant-table-tbody > tr > td {
    white-space: normal !important;
  }
  //ORDER
  #responsive-collapse {
    background: #454f58;
    color: white;
    margin-bottom: 15px;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
  }
  #contenedor-panel {
    margin-bottom: 21px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;
  }
  #collapse-sort-header th,
  .ant-table-column-has-sorters {
    display: block;
  }
  .ant-collapse {
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  .ant-collapse .ant-table-thead {
    display: block;

    .ant-table-column-has-sorters {
      padding-left: 9px !important;
      padding-right: 26px !important;
    }
  }

  .ant-collapse .ant-collapse-content {
    .ant-table-column-title {
      text-align: left;
    }
  }
  .ant-pro-table.responsive-ant-pro-table .ant-table-thead tr {
    display: flex;
    flex-direction: column;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    z-index: 1000;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header,
  .ant-table-cell ant-table-column-has-sorters {
    padding: 12px 16px;
    padding-right: 40px;
  }
  #sorTitle {
    padding: 10px 0;
    margin: 0;
    border-left: 1px solid #454f58;
    border-right: 1px solid #454f58;
  }
  .ant-table-column-sorter-inner {
    zoom: 1.5;
  }
  .spanIcon {
    margin-right: 10px;
    float: left;
    margin-left: 14px;
    height: 24px;
    width: 24px;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 19px !important;
  }
  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: -46% !important;
    color: white !important;
    zoom: 1.3;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    padding-left: 9px !important;
    padding-right: 26px !important;
  }
  #contentCollapseSortHeader
    .ant-table-column-has-sorters
    .ant-table-column-title {
    font-weight: 600 !important;
    text-align: left;
  }
  #contentCollapseSortHeader .ant-table-column-sort .ant-table-column-title {
    font-weight: 700 !important;
  }
}
/* End Responsive ProTable */

/* Responsive ProTable  Expanded-row*/

.ant-pro-table.responsive-ant-pro-table .ant-table-expanded-row {
  td:first-child {
    padding: 0;
  }
  .containterExpandable {
    h5 {
      padding: 10px;
      text-align: center;
      background: #dee1e6;
      margin-bottom: 0;
    }
    table {
      box-shadow: inset 0 0 0 5px #dee1e6;

      td:first-child {
        padding: 12px 8px;
      }
    }

    .ant-card-bordered {
      background: transparent;
      border: none;
    }
    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
      background: none;
    }
  }
}

/* Forms ProTable */

@media screen and (max-width: 40rem) and (orientation: portrait) {
  .SaveForm .ant-modal-body {
    padding: 14px;
  }
  .SaveForm .ant-pro-table-search.ant-pro-table-form {
    padding: 0;
  }

  .SaveForm .SaveFormFooter .ant-btn,
  .containerFormWithStepper .SaveFormFooter .ant-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (prefers-color-scheme: dark) {
  .menuTrigger .anticon {
    color: #ffffff;
  }
  .ant-pro-table.responsive-ant-pro-table
    .ant-table-tbody
    > tr
    > td:last-child {
    border-bottom: 2px solid #ffffff !important;
  }
  .ant-pro-table.responsive-ant-pro-table .anticon {
    color: #a6a6a6 !important;
  }
  .ant-pro-table.responsive-ant-pro-table .anticon.active {
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .ant-pro-table.responsive-ant-pro-table
    .ant-table-tbody
    > tr
    > td:last-child {
    border-bottom: 2px solid #413f3f !important;
  }
  .ant-pro-table.responsive-ant-pro-table .anticon {
    color: #a6a6a6;
  }
  .ant-pro-table.responsive-ant-pro-table .anticon.active {
    color: #000000;
  }
}

// menuTrigger
div.menuTrigger {
  display: flex;
  align-items: center;
  min-height: 38px;
  padding: 3px 7px;
  margin: 3px 0 !important;
  transition: all 0.2s;
  z-index: 4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: 2px solid #ffffff;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

div.menuTrigger svg {
  zoom: 1.35;
}

.buttonChangeProTableView {
  display: flex;
  flex-direction: column;
  background-color: white;
  .ant-btn {
    text-align: left;
  }
}

/* Fin css agregado */

@primary-color: #454f58;