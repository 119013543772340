.doc-generator-page {
  background-color: white;
  border-radius: 15px;
  padding: 20px;

  .ant-space {
    .ant-space-item:first-child {
      width: 100%;
    }
  }
}

@primary-color: #454f58;